import React from "react";

const HomeScreen = () => {
  return (
    <div className="App">
      <h1>Ana sayfa</h1>
    </div>
  );
};

export default HomeScreen;
